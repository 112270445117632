.button-shadcn {
  padding: 0.5rem 1.5rem;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.primary {
    background-color: #000;
    color: #fafafa;
    border: 1px solid gray;

    &:hover:not(:disabled) {
      background-color: #ffffff;
      color: #000000;
    }

    &:active {
      background-color: #ffffff;
    }
  }

  &.secondary {
    background-color: #ffffff;
    color: #111827;
    border: 1px solid #ffffff;

    &:hover:not(:disabled) {
      background-color: #e3e5e9;
    }

    &:active {
      background-color: #e3e5e9;
    }
  }
}
