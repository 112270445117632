/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Mulish&family=Oswald&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* Sass Variables */
$Oswald: 'Oswald', sans-serif;
$Mulish: 'Mulish', sans-serif;
$Roboto: 'Roboto', sans-serif;
$Outfit: 'Outfit', sans-serif;
$main_bg: #000000;
$primary_color: #ffffff;

$default_color: #ffffff;
$color_one: #0004fc;
$color_two: #c300ff;
$color_three: #42ffdb;

// $brand_color: #ff6a96;
$brand_color: linear-gradient(45deg, #ee1e5d, #e644f5);
$brand_color_four: linear-gradient(45deg, #f8501c, #ffab1d);
// $brand_color: linear-gradient(45deg, #149E8E, #42DF90);
$brand_color_hover: linear-gradient(45deg, #0004fc, #c300ff);
// $brand_color_hover: linear-gradient(45deg, #fa5293, #f86b64);
// $brand_color: linear-gradient(45deg, #892168, #FF3B57);
$brand_color_two: linear-gradient(45deg, #42ffdb, #2bdce3);
$brand_color_three: linear-gradient(45deg, #c13dff, #f573c3);
$brand_color_max: linear-gradient(45deg, #4684f6, #44cdef);

$nav_link_color: lightgray;
$transition: all 0.3s ease;

$facebook: #4267b2;
$github: #24292e;
$instagram: linear-gradient(
  45deg,
  #f09433 0%,
  #e6683c 25%,
  #dc2743 50%,
  #cc2366 75%,
  #bc1888 100%
);
$instagram_bg_hover: linear-gradient(45deg, #ffffff, #ffffff);

$linkedin: #0077b5;
$twitter: #1da1f2;
$youtube: #ff0000;
$hackerrank: #1ba94c;
$codepen: $main_bg;

$box_shadow_one: 0px 0px 4px 2px rgb(14, 209, 223);
$box_shadow_four: 0px 0px 4px 2px #ff8800;
$box_shadow_two: 0px 1px 4px 3px rgba(0, 0, 0, 0.1);
$box_shadow_three: 0px 0px 5px 2px rgba(0, 0, 0, 0.342);

// about
$about_bg: #242831;
$new_bg: #000000;
$btn_bg: linear-gradient(45deg, #000000, #000000);
// $btn_bg: linear-gradient(45deg, #ca8a00, #3f0000);

// latest_projects
$slider_overlay: inset 420px 0px 250px #490125;
// $slider_overlay: inset 520px 50px 250px #000000,
// inset 100px -100px 350px #ffffff;

// Neon  colors

$neon_background_one: linear-gradient(195deg, #e100ff, #060c21, #3700ff);
$neon_background_one_main: linear-gradient(195deg, #e100ff, #060c21, #3700ff);

$neon_background_one_hover: linear-gradient(195deg, #3700ff, #060c21, #e100ff);

$neon_background_one_lol: linear-gradient(220deg, #e100ff, #060c21, #3700ff);

$neon_background_one_lol_hover: linear-gradient(
  220deg,
  #3700ff,
  #060c21,
  #e100ff
);

$neon_background_two: linear-gradient(220deg, #ffbb00, #060c21, #d40063);
$neon_background_two_hover: linear-gradient(220deg, #d40063, #060c21, #ffbb00);
$neon_background_three: linear-gradient(220deg, #d40063, #000000, #00bcd4);
$neon_background_three_over: linear-gradient(220deg, #00bcd4, #000000, #d40063);
$neon_background_four: linear-gradient(195deg, #e100ff, #00eeff, #3700ff);
$neon_background_five: linear-gradient(220deg, #e100ff, #00eeff, #3700ff);
$neon_background_six: linear-gradient(to right, #00eeff, #ff00ea, #fffb00);
$neon_background_seven: linear-gradient(to right, #fffb00, #ff00ea, #00eeff);
$neon_background_eight: linear-gradient(to right, #00ffff, #d8dac3, #6f00ff);

// Sills

$js: #efd81d;
$python: '';
$html: '';
$css: '';
$react: '';
$redux: '';
$node: '';
$sass: '';
$bootstrap: '';
$mongodb: '';
$mysql: '';
$firebase: '';
$vscode: '';
$postman: '';
$git: '';
$atom: '';

@mixin displayCenter($justify) {
  display: flex;
  align-items: center;
  justify-content: $justify;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // &::selection {
  //   background: $neon_background_six;
  //   background-clip: text;
  //   -webkit-text-fill-color: transparent;
  //   color: rgb(0, 0, 0);
  // }
}
@supports (scrollbar-color: red blue) {
  html,
  body {
    scrollbar-width: thin !important;
    scrollbar-color: #00e1ff transparent;
  }
}

html,
body {
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: auto;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    margin-block: 0.2em;
    border-radius: 100vw;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(#00fff2, rgb(195, 0, 255));
    border-radius: 100vw;
    &:hover {
      background: linear-gradient(#fffb00, #00eeff);
    }
  }
}

// Update these grid variables
$grid-color-1: rgba(255, 255, 255, 0.1);
$grid-color-2: rgba(255, 255, 255, 0.05);
$grid-size-1: 20px;
$grid-size-2: 100px;

body {
  font-family: $Roboto;
  background: $new_bg !important;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(
        to right,
        rgba(79, 79, 79, 0.18) 1px,
        transparent 1px
      ),
      linear-gradient(to bottom, rgba(128, 128, 128, 0.04) 1px, transparent 1px);
    background-size: 14px 24px;
  }
}

.lightMode {
  background: $primary_color !important;
  color: $about_bg;
  .section_title {
    color: #000;
    font-family: $Outfit !important;
  }
}

// GSAP banner

.new-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: #000;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  text-align: center;
  font-family: $Outfit;
  h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #fafafa;
    margin-bottom: 1.5rem;
    line-height: 1.2;

    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  p {
    font-size: 1.2rem;
    color: #fafafa;
    margin-bottom: 1rem;
    line-height: 1.6;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
    .icon {
      font-size: 25px;
      margin-inline: 0.5rem;
    }

    .highlight {
      font-weight: 600;
      transition: color 0.3s ease;

      &.accessible {
        color: #0077b6;
        &:hover {
          color: #023e8a;
        }
      }
      &.responsive {
        color: #00b4d8;
        &:hover {
          color: #0096c7;
        }
      }
      &.interactive {
        color: #ff9e00;
        &:hover {
          color: #fb8500;
        }
      }
      &.animated {
        color: #9d4edd;
        &:hover {
          color: #7b2cbf;
        }
      }
      &.performance {
        color: #38b000;
        &:hover {
          color: #008000;
        }
      }
    }
  }

  button {
    margin: 1.5rem auto 0 auto;
  }
}

.new-banner-hidden {
  display: none !important;
}

// Global Styles

.arrow_up {
  position: fixed;
  bottom: 0;
  right: 20px;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  z-index: 2;
  border-radius: 5px;
  @include displayCenter(center);
  background-color: $primary_color;
  cursor: pointer;
  box-shadow: $box_shadow_three;
  .icon {
    color: $main_bg;
  }
  &:hover {
    box-shadow: $box_shadow_one;
  }
}
.hide {
  display: none;
}
.link {
  text-decoration: none;
  display: inline-block;
  font-family: $Oswald;
  letter-spacing: 0.5px;
  border-radius: 5px;
  padding: 0.5em 2em;
}
a {
  text-decoration: none;
  display: inline-block;
  box-sizing: $box_shadow_two;
  text-align: center;
  transition: $transition;
  outline: none;
}
.section_title {
  @include displayCenter(center);
  padding: 30px 0 80px 0;
  h1 {
    font-family: $Outfit;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 2.5rem;
    position: relative;
    color: $primary_color;
    background: $neon_background_six;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    &:hover {
      background: $neon_background_seven;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 3px;
      background: $brand_color_hover;
      border-radius: 33px;
      bottom: -0.5em;
    }
  }
}
.about_left,
.download_resume,
.video_card,
.youtube_btn,
.project_card,
.slider_inner,
.contact_left,
.success__message,
.contact_right,
// .icon_wrapper,
.timeline-content,
.main_wrapper {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    z-index: -1;
    background: $neon_background_one;
  }

  &::after {
    position: absolute;
    content: '';
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    z-index: -2;
    background: $neon_background_one;
    filter: blur(5px);
  }
  &:hover {
    &::before {
      background: $neon_background_one_hover;
    }
    &::after {
      background: $neon_background_one_hover;
    }
  }
}

// alerts
.swal-overlay {
  background-color: rgba(0, 35, 99, 0.5);
}
.swal-modal {
  background: transparent;
}

.success__message {
  background: rgb(0, 0, 0) !important;
  padding: 50px 20px;

  .ic {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid rgb(111, 229, 238);
    @include displayCenter(center);
    margin: auto;
    margin-bottom: 30px;
    .ii {
      color: $primary_color;
      font-size: 30px;
    }
  }
  p {
    font-size: 17px;
    font-family: $Mulish;
  }
  h1 {
    font-size: 30px;
    font-family: $Oswald;
  }
  h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-family: $Oswald;
  }
  h1,
  h5 {
    background: $neon_background_eight;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    &:hover {
      background: $neon_background_seven;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  p {
    background: $neon_background_seven;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    &:hover {
      background: $neon_background_six;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &::after,
  &::before {
    background: linear-gradient(155deg, #e100ff, #00eeff, #2f00ff) !important;
  }
  &::after {
    filter: blur(100);
    top: -1px;
    right: -1px;
    left: -1px;
    bottom: -1px;
  }

  &:hover {
    &::after,
    &::before {
      background: linear-gradient(
        to right,
        #fffb00,
        #ff00ea,
        #00eeff
      ) !important;
    }
  }
}

// Header
nav {
  background-color: $main_bg;
  font-family: $Outfit;
  letter-spacing: 0.2px;
  .navbar-toggler-icon {
    color: $primary_color;
  }
  .navbar-brand {
    @include displayCenter(center);
    color: $primary_color;
    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
      margin-right: 5px;
      margin-top: -6px;
    }
    h1 {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .nav-link {
    color: $nav_link_color;
    text-transform: uppercase;
    font-size: 13px;
    border-bottom: 3px solid transparent;
    font-weight: 500;
    &:hover {
      color: $primary_color;
      border-bottom-color: #8601fe;
    }
  }
  // .projects {
  //   color: $primary_color !important;
  //   // animation: glow 1s ease-in-out infinite alternate;
  //   background: $neon_background_six;
  //   background-clip: text;
  //   -webkit-text-fill-color: transparent;
  //   &:hover {
  //     background: $neon_background_seven;
  //     background-clip: text;
  //     -webkit-text-fill-color: transparent;
  //   }
  // }
  .resume {
    border-radius: 5px;
    padding: 0 0.3em !important;
    border-bottom: none;
    background: $brand_color_hover;
    &:hover {
      background: $brand_color_four;
      .nav-link {
        color: $main_bg;
        border-bottom-color: transparent !important;
      }
    }
    .nav-link {
      color: $primary_color;
    }
  }
  .dark {
    color: $primary_color;
    font-size: 22px;
    cursor: pointer;
    margin-top: 5px !important;
  }
  // .moon{
  //   display: none;
  // }
}

//Home - Banner
.banner {
  // background: #09090b;
  // background: url('https://images.unsplash.com/photo-1467232004584-a241de8bcf5d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80')
  //   no-repeat center center/cover;
  // background: url('https://res.cloudinary.com/kirankumargonti/image/upload/v1600403914/Kirankumargonti/lol_h3gwd0.jpg')
  //   no-repeat center center/cover;
  // background: url('https://ik.imagekit.io/kirankumargonti/pexels-xxss-is-back-777001_d0c0jieXb.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1663081927431')

  // background: url('https://images.pexels.com/photos/12491653/pexels-photo-12491653.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')
  //   no-repeat center center/cover;
  height: 100vh;
  position: relative;
  @include displayCenter(center);
  color: $primary_color;
  // background: radial-gradient(circle 500px at 50% 200px, #3e3e3e, transparent);

  // &::after {
  //   position: absolute;
  //   content: '';
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 1;
  //   background: rgba(0, 0, 0, 0.7);
  // }
  .banner_content {
    @include displayCenter(flex-start);
    flex-direction: column;
    font-family: $Outfit;
    position: relative;
    z-index: 2;
    max-width: 900px;
    margin: auto;
    color: white;

    h2 {
      letter-spacing: 1px;
      // font-weight: 500;
    }
    h1 {
      font-size: 4.2rem;
      // font-weight: 500;
    }

    p {
      // line-height: 26px;
      text-align: center;
      margin: 15px 0 30px 0;
      font-size: 20px;
      color: aliceblue;
    }
    .banner_buttons {
      @include displayCenter(center);
      flex-wrap: wrap;
      margin: 30px 0 0 0;
      gap: 20px;
    }
  }
}

//Home - Social links
.social_links {
  @include displayCenter(center);
  flex-wrap: wrap;
  width: 100%;
  a {
    color: $main_bg;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    margin: auto;
    @include displayCenter(center);
    color: $primary_color;
  }
  .github {
    color: $github;
    background-color: $primary_color;
    &:hover {
      color: $primary_color;
      background-color: $github;
    }
  }
  .linkedin {
    background-color: $linkedin;
    &:hover {
      background-color: $primary_color;
      color: $linkedin;
    }
  }
  .youtube_link {
    background-color: $youtube;
    &:hover {
      background-color: $primary_color;
      color: $youtube;
    }
  }
  .codepen {
    background-color: $primary_color;
    color: $codepen;
    &:hover {
      background-color: $codepen;
      color: $primary_color;
    }
  }
  .instagram {
    background: $instagram;
    &:hover {
      color: #f09433;
      background: $instagram_bg_hover;
    }
  }
  .facebook {
    background-color: $facebook;
    &:hover {
      background-color: $primary_color;
      color: $facebook;
    }
  }
  .hackerrank {
    background-color: $hackerrank;
    &:hover {
      background-color: $primary_color;
      color: $hackerrank;
    }
  }
  .twitter {
    background-color: $twitter;
    &:hover {
      background-color: $primary_color;
      color: $twitter;
    }
  }
}

//Slider
.swiper-button-prev,
.swiper-button-next {
  color: $primary_color !important;
}
.swiper-pagination-bullet-active {
  background: $primary_color !important;
}
.swiper-container {
  overflow: hidden;
  cursor: pointer;
  .swiper-slide {
    overflow: hidden;
    padding: 20px 0 50px 0;
    .slider_inner {
      @include displayCenter(space-between);
      width: 100%;
      height: 60vh;
      border-radius: 10px;
      background-color: $main_bg;
      color: $primary_color;
      &::before {
        border-radius: 10px;
        background: $neon_background_four !important;
      }
      &::after {
        top: -2px;
        bottom: -2px;
        left: -2px;
        right: -2px;
        border-radius: 10px;
        background: $neon_background_four !important;
      }
      img {
        width: 65%;
        height: 100%;
        object-fit: fill;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      .slider_text {
        @include displayCenter(center);
        flex-direction: column;
        padding: 0 20px;
        font-family: $Oswald;
        letter-spacing: 0.5px;
        h1 {
          font-size: 2.2rem;
          background: $neon_background_six;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          &:hover {
            background: $neon_background_seven;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        p {
          font-size: 15px;
          text-align: justify;
          margin: 15px 0;
          font-family: $Roboto;
          line-height: 26px;
        }
        .project_links {
          @include displayCenter(space-evenly);
          width: 100%;
          padding: 10px 0 0 0;
          a {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $primary_color;
            @include displayCenter(center);
            &:hover {
              box-shadow: $box_shadow_one;
              transform: scale(1.1);
            }
            .icon {
              color: $main_bg;
            }
          }
        }
        .tech_stack {
          @include displayCenter(space-evenly);
          width: 100%;
          flex-wrap: wrap;
          padding: 10px 0 20px 0;
          .icon {
            font-size: 1.5rem;
          }
          img {
            width: 8%;
          }
        }
      }
    }
  }
}

// Home - About
.about {
  color: $primary_color;
  padding: 30px 0;
  text-align: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    height: 100vh;
    width: 100vw;
    background: radial-gradient(
      ellipse 80% 80% at 50% -20%,
      rgba(119, 140, 198, 0.3),
      rgba(255, 255, 255, 0)
    );
  }
  // .about_left {
  //   @include displayCenter(flex-start);
  //   width: 300px;
  //   height: 300px;
  //   border-radius: 50%;
  //   background: $about_bg;
  //   margin: auto;
  //   &:hover {
  //     &::after {
  //       filter: blur(200px) !important;
  //       background: $neon_background_four;
  //     }
  //   }
  //   &::before {
  //     border-radius: 50%;
  //     background: $neon_background_four;
  //   }
  //   &::after {
  //     border-radius: 50%;
  //     background: transparent;
  //   }
  //   img {
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 50%;
  //     object-fit: cover;
  //     cursor: pointer;
  //   }
  // }
  // .about_right {
  //   @include displayCenter(center);
  //   flex-direction: column;
  //   font-family: $Oswald;
  //   h1 {
  //     letter-spacing: 1px;
  //     background: $neon_background_seven;
  //     background-clip: text;
  //     -webkit-text-fill-color: transparent;

  //     &:hover {
  //       background: $neon_background_six;
  //       background-clip: text;
  //       -webkit-text-fill-color: transparent;
  //     }
  //   }
  //   h3 {
  //     margin: 5px 0;
  //     font-size: 20px;
  //     background: $neon_background_six;
  //     background-clip: text;
  //     -webkit-text-fill-color: transparent;

  //     &:hover {
  //       background: $neon_background_seven;
  //       background-clip: text;
  //       -webkit-text-fill-color: transparent;
  //     }
  //   }
  //   p {
  //     line-height: 32px;
  //     text-align: justify;
  //     font-size: 18px;
  //     font-family: $Mulish;
  //     margin: 10px 0;
  //   }
  // }
  p {
    line-height: 32px;
    font-size: 18px;
    font-family: $Outfit;
    margin: 40px 0;
    text-align: justify;
  }
  button {
    a {
      color: $primary_color;
      font-weight: 500;
      font-family: $Outfit;
    }
    &:hover {
      a {
        color: black;
      }
    }
  }
}
.swinger {
  max-width: 350px;
  height: 400px;
  margin: auto;
  // border: 2px solid #ffffff;
  // box-shadow: 0px 10px 40px rgba(0, 183, 255, 0.747);
  position: relative;
  animation: swinger 1s ease-in-out alternate infinite;
  transform-origin: center -70px;
  // border-radius: 5px;
  background: linear-gradient(45deg, #4684f6, #ff00e6, #42ffdb);
  padding: 2px; // This creates the border thickness
  box-shadow: 0px 10px 40px rgba(0, 183, 255, 0.747);

  &::before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    top: -75px;
    background-color: #85157a;
    left: 50%;
    transform: translateX(-15%);
    border-radius: 50%;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.5);
  }

  &::after {
    position: absolute;
    content: '';
    width: 8px;
    height: 76px;
    top: -73px;
    border: 1px solid #42ffdb;
    left: 50%;
    z-index: -1;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.5);
  }
  @keyframes swinger {
    0% {
      transform: rotate(1deg);
    }
    100% {
      transform: rotate(-1deg);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

// Home - latestProjects
.latest_projects {
  padding: 30px 0;
}

// Home - Skills
.skills {
  padding: 60px 0;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 30px;
  }

  .skill-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }

    svg {
      margin-bottom: 10px;
      color: $primary_color;
    }

    span {
      font-size: 14px;
      color: $primary_color;
      font-family: $Mulish;
    }
  }
}

// Home - Youtube
.youtube {
  @include displayCenter(center);
  padding: 60px 0;
  color: $primary_color;
  font-family: $Mulish;
  .youtube_content {
    @include displayCenter(center);
    flex-direction: column;
    h2 {
      padding: 10px 0;
      font-family: $Outfit;
      letter-spacing: 0.5px;
      background: $neon_background_six;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      &:hover {
        background: $neon_background_seven;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    p {
      font-size: 18px;
      text-align: center;
    }
    .youtube_logo {
      .icon {
        font-size: 3rem;
        color: $youtube;
      }
    }
    .youtube_buttons {
      @include displayCenter(space-between);
      flex-wrap: wrap;
      .youtube_btn {
        color: $primary_color;
        font-family: $Oswald;
        margin: 40px 15px 10px 15px;
        padding: 0.5em 2em;
        background: $main_bg;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-size: 14px;
        @include displayCenter(center);
        .icon {
          font-size: 1.6rem;
          color: $youtube;
          margin-left: 5px;
        }
      }
      .github_btn {
        .icon {
          color: $primary_color;
          margin: 0 5px 0 0;
        }
      }
    }
    .youtube_videos {
      @include displayCenter(center);
      flex-wrap: wrap;
      margin: 20px 0;
      .video_card {
        flex: 0 1 350px !important;
        margin: 20px;
        background: $main_bg;
        height: 260px;
        &::before {
          background: $neon_background_four;
          top: -2px;
          bottom: -2px;
          right: -2px;
          left: -2px;
        }
        &::after {
          background: $neon_background_one_lol;
          top: -10px;
          bottom: -10px;
          right: -10px;
          left: -10px;
        }
        &:hover {
          .video_thumbnail {
            &::after {
              z-index: -1;
            }
            img {
              transform: scale(1.04);
            }
          }
        }
        .video_thumbnail {
          overflow: hidden;
          width: 100%;
          height: 190px;
          img {
            overflow: hidden;
            transition: $transition;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .video_title {
          position: relative;
          z-index: 2;
          h4 {
            color: $primary_color;
            font-size: 1em;
            padding: 5px;
          }
        }
      }
    }
  }
}

// Home -Timeline
.timeline__ {
  .main-timeline {
    .timeline {
      border-radius: 5px !important;
      .timeline-icon {
        .timeline__icon {
          font-size: 3rem;
        }
      }
    }
  }
  .main-timeline {
    overflow: hidden;
    position: relative;
  }
  .main-timeline .timeline {
    position: relative;
    margin-top: -81px;
    &:nth-child(2) {
      margin-top: -81px;
    }
    &:nth-child(3) {
      // margin-top: -93px;
    }
  }
  .main-timeline .timeline:first-child {
    margin-top: 0;
  }
  .main-timeline .timeline:before,
  .main-timeline .timeline:after {
    content: '';
    display: block;
    width: 100%;
    clear: both;
  }
  .main-timeline .timeline:before {
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: -8px 0 5px -5px rgba(11, 255, 235, 0.5) inset;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
  .main-timeline .timeline-icon {
    width: 210px;
    height: 210px;
    border-radius: 50%;
    border: 25px solid transparent;
    border-top-color: #f44556;
    border-right-color: #f44556;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    transform: rotate(45deg);
  }
  .main-timeline .year {
    display: block;
    width: 110px;
    height: 110px;
    line-height: 110px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    margin: auto;
    font-size: 30px;
    font-weight: bold;
    color: #f44556;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: rotate(-45deg);
  }
  .main-timeline .timeline-content {
    width: 35%;
    float: right;
    background: #000000 !important;
    padding: 30px 20px;
    margin: 50px 0;
    position: relative;
    &::before,
    &::after {
      background: $neon_background_four;
    }
    h3 {
      background: $neon_background_six;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: $Outfit;
      &:hover {
        background: $neon_background_seven;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .present {
      animation: glow 1s ease-in-out infinite alternate;
      font-weight: bold;
      font-family: $Oswald;
      letter-spacing: 1px;
      margin-left: 20px;
    }
  }
  .main-timeline .timeline-content:before {
    content: '';
    width: 20%;
    height: 15px;
    background: #f44556;
    position: absolute;
    top: 50%;
    left: -20%;
    // z-index: -1;
    transform: translateY(-50%);
  }
  .main-timeline .title {
    font-size: 20px;
    letter-spacing: 0.5px;
    color: #fff;
    margin: 0 0 10px 0;
    font-family: $Oswald;
  }
  .main-timeline .description {
    font-size: 16px;
    color: #fff;
    line-height: 24px;
    margin: 0;
    font-family: $Mulish;
  }

  .main-timeline .timeline:nth-child(2n):before {
    box-shadow: 8px 0 5px -5px rgba(11, 255, 235, 0.5) inset;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-icon {
    transform: rotate(-135deg);
  }
  .main-timeline .timeline:nth-child(2n) .year {
    transform: rotate(135deg);
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    float: left;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content:before {
    left: auto;
    right: -20%;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-icon {
    border-top-color: #e97e2e;
    border-right-color: #e97e2e;
  }
  .main-timeline .timeline:nth-child(2n) .year {
    color: #e97e2e;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content,
  .main-timeline .timeline:nth-child(2n) .timeline-content:before {
    background: #e97e2e;
  }
  .main-timeline .timeline:nth-child(3n) .timeline-icon {
    border-top-color: #13afae;
    border-right-color: #13afae;
  }
  .main-timeline .timeline:nth-child(3n) .year {
    color: #13afae;
  }
  .main-timeline .timeline:nth-child(3n) .timeline-content,
  .main-timeline .timeline:nth-child(3n) .timeline-content:before {
    background: #13afae;
  }
  .main-timeline .timeline:nth-child(4n) .timeline-icon {
    border-top-color: #105572;
    border-right-color: #105572;
  }
  .main-timeline .timeline:nth-child(4n) .year {
    color: #105572;
  }
  .main-timeline .timeline:nth-child(4n) .timeline-content,
  .main-timeline .timeline:nth-child(4n) .timeline-content:before {
    background: #105572;
  }
  @media only screen and (max-width: 1399px) {
    .main-timeline .timeline {
      &:nth-child(2) {
      }
      &:nth-child(3) {
        margin-top: -93px;
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    .main-timeline .timeline {
      margin-top: -103px;
    }
    .main-timeline .timeline-content:before {
      left: -18%;
    }
    .main-timeline .timeline:nth-child(2n) .timeline-content:before {
      right: -18%;
    }
  }
  // @media only screen and (max-width: 990px) {
  //   .main-timeline .timeline {
  //     margin-top: -127px;
  //   }
  //   .main-timeline .timeline-content:before {
  //     left: -2%;
  //   }
  //   .main-timeline .timeline:nth-child(2n) .timeline-content:before {
  //     right: -2%;
  //   }
  // }
  @media only screen and (max-width: 990px) {
    .main-timeline .timeline {
      margin-top: 0;
      overflow: hidden;
      // margin-top: 0px;
    }
    .main-timeline .timeline:before,
    .main-timeline .timeline:nth-child(2n):before {
      box-shadow: none;
    }
    .main-timeline .timeline-icon,
    .main-timeline .timeline:nth-child(2n) .timeline-icon {
      margin-top: -30px;
      margin-bottom: 20px;
      position: relative;
      transform: rotate(135deg);
    }
    .main-timeline .year,
    .main-timeline .timeline:nth-child(2n) .year {
      transform: rotate(-135deg);
    }
    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content {
      width: 100%;
      float: none;
      border-radius: 0 0 20px 20px;
      text-align: center;
      padding: 25px 20px;
      margin: 0 auto;
    }
    .main-timeline .timeline-content:before,
    .main-timeline .timeline:nth-child(2n) .timeline-content:before {
      width: 15px;
      height: 25px;
      position: absolute;
      top: -22px;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, 0);
    }
    .main-timeline {
      .timeline {
        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0px !important;
        }
      }
    }
  }
}

// Home - Contact
.contact {
  padding: 30px 0 150px 0;
  .contact_left,
  .contact_right {
    position: relative;
    font-family: $Outfit;
    color: $primary_color;
    background: #000;
    border-top-left-radius: 5%;
    border-bottom-right-radius: 5%;
    @include displayCenter(center);
    height: 100%;
    &:hover {
      &::after {
        background: $neon_background_four;
        filter: blur(200px);
      }
    }
    &::before {
      background: $neon_background_four;
      border-bottom-right-radius: 5%;
      border-top-left-radius: 5%;
    }
    &::after {
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      background: $neon_background_four;
      border-bottom-right-radius: 5%;
      border-top-left-radius: 5%;
    }
    h2 {
      font-family: $Outfit;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      background: $neon_background_six;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      &:hover {
        background: $neon_background_seven;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .contact_left_inner {
    @include displayCenter(center);
    padding: 30px 15px;
    height: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    .home,
    .mail,
    .phone {
      @include displayCenter(flex-start);
      align-items: flex-start;
      margin: 20px 0;
      h4 {
        // background: linear-gradient(to right, #30cfd0 0%, #ff00c8 100%);
        background: $neon_background_six;
        background-clip: text;
        font-family: $Outfit;
        -webkit-text-fill-color: transparent;
        font-weight: 500;
        &:hover {
          background: $neon_background_seven;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      a {
        color: $primary_color !important;
        &:hover {
          text-decoration: underline;
        }
      }
      p {
        margin: auto;
      }
      .icon {
        font-size: 1.8rem;
        margin-right: 15px;
      }
    }
    .mail {
      .icon {
        color: red;
      }
    }
    .contact_social_links {
      margin: 10px 0;
      a {
        margin: 10px 15px;
        width: 30px;
        height: 30px;
        // box-shadow: $box_shadow_two;
      }
      .github {
        margin-left: 0;
      }
    }
  }
  .contact_right {
    @include displayCenter(center);
    flex-direction: column;
    padding: 30px 0;

    form {
      padding: 15px 0;
      max-width: 310px;
      .form_control {
        margin-bottom: 5px;
      }
      .warning {
        color: $youtube !important;
      }
      .error {
        color: $youtube;
        max-width: 310px;
        min-width: 100%;
        margin-top: -12px;
        text-align: right;
        font-size: x-small;
      }
      input,
      textarea {
        border: 1px solid rgb(210, 210, 214);
        font-size: 1em;
        color: $primary_color;
        padding: 0.5em 1em;
        min-width: 100%;
        border-radius: 5px;
        background-color: transparent;
        outline: 0;
        margin: 5px 0 15px 0;

        &::placeholder {
          font-size: 0.7em;
          opacity: 0.6;
        }
      }
      .hire_btn {
        display: block;
        border: 0;
        width: 100%;
        outline: none;
        padding: 0.4em 1em;
        text-align: center;
        border-radius: 5px;
        color: $primary_color;
        font-family: $Oswald;
        text-transform: uppercase;
        letter-spacing: 1px;
        background: $brand_color_hover;
        &:hover {
          background: $brand_color;
        }
        .hire_btn_icon {
          margin: -5px 0 0 5px;
          font-size: 1.4rem;
        }
      }
      .hire_btn_disabled {
        cursor: not-allowed;
        background: $brand_color_four !important;
        display: block;
        border: 0;
        width: 100%;
        outline: none;
        padding: 0.4em 1em;
        text-align: center;
        border-radius: 5px;
        color: $primary_color;
        .spinner {
          margin-top: -5px;
          font-size: 1.4rem;
          animation: spinner linear 1s infinite;
        }
        @keyframes spinner {
          0% {
            transform: rotate(0deg);
          }
          25% {
            transform: rotate(90deg);
          }
          50% {
            transform: rotate(180deg);
          }
          75% {
            transform: rotate(240deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

// footer
footer {
  padding: 20px 10px;
  text-align: center;
  text-transform: capitalize;
  background: rgb(0, 12, 29);
  font-family: $Mulish;
  @include displayCenter(center);
  position: relative;
  h4 {
    font-size: 15px;
    color: $primary_color;
  }
  .footer_links {
    a {
      margin: 5px 15px;
      background-color: transparent !important;
      background: transparent !important;
      color: $primary_color !important;
      box-shadow: $box_shadow_two;
      &:hover {
        transform: scale(1.1);
        color: $main_bg !important;
        background-color: $primary_color !important;
        background: $primary_color !important;
      }
    }
  }
}

// projects

// projects-banner
.all_projects {
  .section_title {
    margin-top: 50px;
  }
  .filter__projects {
    @include displayCenter(flex-end);
    form {
      select {
        width: 250px;
        background-color: #000000;
        cursor: pointer;
        color: #ffffff;
        // border-color: $neon_background_two;
        &:focus {
          outline: 0 !important;
          border-color: inherit !important;
        }
        option {
          background-color: rgb(0, 0, 0) !important;
          cursor: pointer;
          &:hover {
            background: #000 !important;
          }
        }
      }
    }
  }
}

.projects_banner {
  @include displayCenter(center);
  /* background: url('https://images.pexels.com/photos/5224736/pexels-photo-5224736.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500')
    no-repeat center center/cover; */
  background: url('https://ik.imagekit.io/kirankumargonti/richard-horvath-_nWaeTF6qo0-unsplash_lC3mec6_z.jpg?updatedAt=1680070348614')
    no-repeat center center/cover;
  height: 70vh;
  position: relative;
  font-family: $Mulish;
  text-transform: uppercase;
  color: $primary_color;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.616);
  }
  .projects_content {
    position: relative;
    z-index: 2;
    p {
      font-size: 14px;
    }
    .projects_links {
      @include displayCenter(flex-start);
      a {
        width: 50px;
        height: 50px;
        margin: 30px 15px 0 15px;
        border-radius: 50%;
        background-color: $primary_color;
        @include displayCenter(center);
        &:hover {
          box-shadow: $box_shadow_one;
          transform: scale(1.1);
        }
        .icon {
          font-size: 1.5rem;
          color: $main_bg;
        }
      }
      .github_projects_link {
        margin-left: 0;
      }
    }
  }
}

.projects {
  .swiper-slide {
    padding-top: 20px;
  }
}

// projects -all projects
.projects_wrapper {
  @include displayCenter(center);
  flex-wrap: wrap;
  padding: 30px 0;
  .project_card {
    background: $main_bg;
    flex: 0 1 600px;
    position: relative;
    margin: 10px;
    height: 400px;
    border-radius: 5px;
    color: $primary_color;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:hover {
      .project-info {
        opacity: 1;
      }
      &::after {
        top: -15px;
        bottom: -15px;
        left: -15px;
        right: -15px;
        background: $neon_background_four;
        filter: blur(200px);
      }
    }

    &::before {
      background: $neon_background_five;
      border-radius: 5px;
    }

    &::after {
      background: $neon_background_five;
      filter: blur(10px);
      border-radius: 5px;
    }
    a {
      color: $primary_color;
    }

    // .project_card_img {
    //   position: relative;
    //   transform: $transition;
    //   width: 100%;
    //   height: 250px;
    //   background-color: red;
    //   &::after {
    //     position: absolute;
    //     content: '';
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 1;
    //     background: rgba(1, 35, 48, 0.589);
    //   }
    //   img {
    //     object-fit: cover;
    //     width: 100%;
    //     height: 100%;
    //   }
    // }
    .project-info {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.856);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      .project_description {
        text-align: center;
        padding: 10px 0 0 0;
        h3 {
          font-size: 1.5rem;
          text-transform: uppercase;
          font-family: $Outfit;
          letter-spacing: 1px;
        }
        p {
          font-size: 15px;
          text-transform: capitalize;
        }
      }
      .project_links {
        margin-top: auto;
        @include displayCenter(space-evenly);
        width: 100%;
        a {
          width: 40px;
          height: 40px;
          background-color: $primary_color;
          border-radius: 50%;
          @include displayCenter(center);
          &:hover {
            box-shadow: $box_shadow_one;
            transform: scale(1.1);
          }
          .icon {
            color: $main_bg;
          }
        }
      }
    }
  }
}
// notfound
.notfound {
  width: 100%;
  height: 81.7vh;
  color: $primary_color;
  padding: 10px;
  @include displayCenter(center);
  position: relative;

  .notfound_content {
    @include displayCenter(center);
    flex-direction: column;
    position: relative;
    text-transform: capitalize;
    font-family: $Mulish;
    img {
      width: 100px;
    }
    h1 {
      font-size: 4rem;
    }
    h5 {
      font-size: 18px;
    }
    .back_to_home {
      background: $brand_color_hover;
      color: $primary_color;
      margin: 20px 0;
      .icon {
        color: $primary_color;
        font-size: 1.6rem;
        margin-right: 5px;
      }
      &:hover {
        background: $brand_color;
      }
    }
  }
}

// KeyFrames
@keyframes glow {
  from {
    text-shadow: 0 0 10px $default_color, 0 0 20px $default_color,
      0 0 5px $color_one, 0 0 5px $color_one, 0 0 40px $color_one,
      0 0 50px $color_one, 0 0 60px $color_one;
  }
  to {
    text-shadow: 0 0 10px $default_color, 0 0 20px $color_two,
      0 0 5px $color_two, 0 0 30px $color_two, 0 0 40px $color_two,
      0 0 50px $color_two, 0 0 60px $color_two;
  }
}

/* =========== media Queries ============= */
@media (max-width: 992px) {
  .nav-item {
    margin: 0 5px !important;
  }
  .tech_stack {
    img {
      width: 15% !important;
    }
  }
  .contact {
    padding-bottom: 10px;
  }
}
@media (max-width: 831px) {
  .slider_inner {
    padding: 0 50px;
    .tech_stack {
      img {
        width: 15% !important;
      }
    }
  }
}
@media (max-width: 768px) {
  .navbar-brand {
    h1 {
      visibility: hidden;
    }
    img {
      width: 40px !important;
      height: 40px !important;
      margin: 0 !important;
    }
  }
  .nav-item {
    margin: 5px 20px 5px auto !important;
    letter-spacing: 1px;
  }
  .resume {
    .nav-link {
      padding: 8px !important;
    }
  }
  .slider_inner {
    height: auto !important;
    flex-wrap: wrap;
    padding: 0;
    img {
      order: 1;
      width: 100% !important;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 0 !important;
    }
    .slider_text {
      padding: 15px 10px !important;
      order: 2;
      align-items: center !important;
      h1 {
        font-size: 1.5rem !important;
      }
      p {
        margin: 5px 0 !important;
      }
      .tech_stack {
        img {
          width: 8% !important;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .success__message {
    padding: 20px 5px;
  }
  // banner
  .banner {
    height: 80vh;
  }
  .banner_content {
    h1 {
      font-size: 2rem !important;
    }

    h2 {
      font-size: 1.4rem !important;
    }

    p {
      font-size: 15px !important;
      margin: 10px 10px 30px 10px !important;
    }

    .projects_btn,
    .hire_btn {
      width: auto !important;
      margin: 30px 10px 0 10px !important;
    }
  }
  // social links
  .social_links {
    a {
      width: 30px;
      height: 30px;
    }
  }
  // global styles
  .section_title {
    h1 {
      font-size: 1.5rem;
      font-family: $Outfit !important;
    }
  }

  // slider
  .slider_text {
    p {
      font-size: 12px !important;
    }
    .tech_stack {
      img {
        width: 8% !important;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
  // about
  .swinger {
    height: 350px;

    &::before {
      top: -82px;
    }

    &::after {
      height: 81px;
      top: -78px;
    }
  }

  .about {
    p {
      line-height: 24px !important;
      font-size: 14px !important;
    }
  }
  .about_left {
    width: 220px !important;
    height: 220px !important;
  }
  .about_right {
    padding: 10px 0;
    h1 {
      font-size: 1.3em;
    }
    h3 {
      font-size: 1em !important;
    }
    p {
      line-height: 24px !important;
      font-size: 13px !important;
      font-family: $Mulish;
    }
  }
  // skills
  .skills {
    .database,
    .tools,
    .web,
    .programming {
      align-items: flex-start;
      margin: 15px 0;
      h2 {
        font-size: 18px;
      }
    }
    .icon_wrapper {
      width: 50px !important;
      height: 50px !important;
      .icon {
        font-size: 1.8rem !important;
      }
      .tooltiptext {
        visibility: hidden !important;
        display: none;
      }
    }
  }
  // youtube
  .youtube_content {
    p {
      font-size: 14px !important;
    }
  }
  .youtube_buttons {
    .youtube_btn {
      margin: 40px 5px 10px 5px !important;
      padding: 0.5em 1em !important;
    }
  }
  // contact
  .contact_right {
    h2 {
      font-size: 1.2rem;
    }
  }
  .home,
  .mail,
  .phone {
    h4 {
      font-size: 18px;
    }
    a,
    p {
      font-size: 13px !important;
    }
  }
  .contact_social_links {
    text-align: center;
    h2 {
      font-size: 1.2rem;
    }
  }
  .contact_right {
    padding: 0 10px;
  }
  // footer
  .arrow_up {
    width: 35px !important;
    height: 35px !important;
  }
  /*==================== Projects=========== */
  .all_projects {
    .section_title {
      padding: 30px 0 30px 0;
    }
  }
  .projects_banner {
    .projects_content {
      text-align: center;
      .projects_links {
        @include displayCenter(center);
        a {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .project_description {
    h3 {
      font-size: 1.1rem !important;
    }
    p {
      font-size: 14px !important;
    }
  }
}

@media screen and (max-width: 450px) {
  .project_card {
    height: 200px !important;
  }
}

// progressbar
.progress-bar {
  position: fixed;
  top: 0;
  width: 0;
  height: 3px;
  z-index: 1031;
  background: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  animation: progress 1s linear infinite;
  border-radius: 5px;
}

@keyframes progress {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

// website loading

.ipl-progress-indicator.available {
  opacity: 0;
}
.ipl-progress-indicator {
  background: $about_bg;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 1;
  pointer-events: none;
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  z-index: 1031;
}
.insp-logo-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadein 436ms;
  height: 100vh;
  .main_wrapper {
    width: 100px;
    height: 100px;
    background: $about_bg;
    border-radius: 50%;
    position: relative;
    &::before {
      top: -3px;
      bottom: -3px;
      left: -3px;
      right: -3px;
      border-radius: 50%;
      background: $neon_background_four;
    }
    &::after {
      top: -50px !important;
      bottom: -50px !important;
      left: -50px !important;
      right: -50px !important;
      background: $neon_background_four;
      border-radius: 50%;
      filter: blur(100px) !important;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }
}

.ipl-progress-indicator-head {
  background: transparent;
  height: 3px;
  overflow: hidden;
  position: relative;
}
.ipl-progress-indicator .first-indicator,
.ipl-progress-indicator .second-indicator {
  background: $neon_background_four;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  transform-origin: left center;
  transform: scaleX(0);
}
.ipl-progress-indicator .first-indicator {
  animation: first-indicator 2s linear infinite;
}
.ipl-progress-indicator .second-indicator {
  animation: second-indicator 2s linear infinite;
}
.ipl-progress-indicator .insp-logo {
  animation: App-logo-spin infinite 20s linear;
  border-radius: 50%;
  align-self: center;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes first-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }
  25% {
    transform: translate(0) scaleX(0.5);
  }
  50% {
    transform: translate(25%) scaleX(0.75);
  }
  75% {
    transform: translate(100%) scaleX(0);
  }
  100% {
    transform: translate(100%) scaleX(0);
  }
}
@keyframes second-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }
  60% {
    transform: translate(0) scaleX(0);
  }
  80% {
    transform: translate(0) scaleX(0.6);
  }
  100% {
    transform: translate(100%) scaleX(0.1);
  }
}
.btn-stars {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  overflow: hidden;
  height: 3rem;
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 5px;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: 2px 4px transparent;
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      #ffdb3b 10%,
      #fe53bb 45%,
      #8f51ea 67%,
      #0044ff 87%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
}

#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5px;
}

strong {
  z-index: 2;
  font-family: $Oswald;
  font-size: 12px;
  letter-spacing: 2px;
  color: #ffffff;
  text-shadow: 0 0 4px white;
}

#glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

.btn-stars:hover #container-stars {
  z-index: 1;
  background-color: #212121;
}

.btn-stars:hover {
  transform: scale(1.1);
}

.btn-stars:active {
  border: double 4px #fe53bb;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.btn-stars:active .circle {
  background: #fe53bb;
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: '';
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
